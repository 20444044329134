@import "themes/constants.module.scss";

.root {
	.pre-line {
		white-space: pre-line;
	}

	.return-button-container {
		padding-bottom: 16px;

		svg {
			min-width: 20px;
			max-width: 20px;
			min-height: 20px;
			max-height: 20px;
		}
	}

	.author {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
		gap: 16px;

		.avatar {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}

		.publication {
			display: flex;
			flex-direction: column;
			.at {
				color: #6b7280;
			}
		}
	}
}

@import "themes/constants.module.scss";

.root {
    .author {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        gap: 16px;

		.avatar-container {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;

			.avatar {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}

        .publication {
            display: flex;
            flex-direction: column;
            .at {
                color: #6B7280;
            }
        }
    }
}
